import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Link from 'gatsby-link'

export default class CollapsePage extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      collapseID: '',
    }
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }))
  }

  render() {
    const az = this.props.data.sort((a, b) => a.node.frontmatter.title.toUpperCase() == b.node.frontmatter.title.toUpperCase() ? 0 : a.node.frontmatter.title.toUpperCase() < b.node.frontmatter.title.toUpperCase() ? -1 : 1)
    let tmp = []
    const az2 = []
    for (var i = 0, l1 = az.length; i < l1; i++) {
      // This loop is for inner-arrays
      if ((i > 0 && (az[i].node.frontmatter.title.substring(0, 1)) !== az[i > 0 ? i-1 : 0].node.frontmatter.title.substring(0, 1))) {
        // Accessing each elements of inner-array
        az2.push({letter: az[i-1].node.frontmatter.title.substring(0, 1).toUpperCase(), products: tmp.flat()})
        // console.log({letter: az[i-1].node.frontmatter.title.substring(0, 1).toUpperCase(), products: tmp.flat()})
        tmp = []
        tmp.push(az[i]) 
      } else {
        tmp.push(az[i]) 
      }
      i===l1-1 && az2.push({letter: az[i].node.frontmatter.title.substring(0, 1).toUpperCase(), products: tmp.flat()})
    }

    return (
      <section className="bg-light-blue py-5 rounded-bottom">
        <MDBContainer>
          <MDBRow className="ml-xl-4 ml-2">
            <MDBCol lg="12">
              {az2.map((alpha, index) => (
                <MDBRow className="py-3" key={index}>
                  <MDBCol md="2" className="px-4 pb-3">
                    <span className="az-index font-alt">{alpha.letter}</span>
                  </MDBCol>
                  <MDBCol md="10">
                    <MDBRow className="pt-4">
                    {alpha.products.map((product, index) => (
                      <MDBCol md="12" className="pb-4" key={index}>
                        <p className="font-w-700 text-medium text-md-medium mb-1">
                          <Link to={product.node.fields.slug} className="effect">
                            {`${product.node.frontmatter.title} - ${product.node.frontmatter.name}`}
                          </Link>
                        </p>
                        <p className="font-w-400 text-medium text-md-medium">
                        {product.node.frontmatter.subtitle}
                        </p>
                      </MDBCol>
                    ))
                    }
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              ))}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}